import React from "react";
import Linkedin from "../svgs/linkedin";
import Yt from "../svgs/yt";
import Art from "../svgs/art";
import Behance from "../svgs/behance";
import Github from "../svgs/github";

export default function Portfolio() {
  return (
    <div
      className="pt-20 px-10 md:px-20 bg-[#222A36] min-h-screen"
      id="portfolio"
    >
      <p className="text-white text-center md:text-left text-[48px] md:text-[48px] mb-10 lg:text-[96px]">
        Portfolio
      </p>
      <div class="grid sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 text-white ">
        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">Sketch2Life</h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Unity 3D</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Easy AR</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Android</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>C#</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Firebase</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This was a Unity based client-server system generating and
            displaying 3D renders to the house floorplans to its consumers. The
            idea was that in a time where things are getting virtual, so should
            viewing houses. My responsibility was developing and designing the
            AR front end to our system that generates 3D models of the supplied
            floorplans, along with necessary documentation and report work
          </p>
          <div className=" flex flex-row justify-center my-4">
            <a
              href="https://www.youtube.com/watch?v=sPvCgL97oOw&list=PL7tuQBvlkyi9S2csJmB-QexLzB_BuNDTF&index=1&t=1s"
              target="_blank"
            >
              <Yt />
            </a>
          </div>
        </div>
        {/*  */}
        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">Projects for THH</h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Unity 3D</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Vuforia</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Android</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>C#</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Firebase</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Windows</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Ardity</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Arduino</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>C#</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>C</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This is a compilation of some of the projects I have done at THH,
            the company I previously worked at. Some of the projects include 2
            activations for Dubai Municipality
            
          </p>
          <div className=" flex flex-row justify-center my-4">
            <a href="https://github.com/Loner291999/some-projects-at-THH" target="_blank">
              <Github />
            </a>
          </div>
        </div>

        {/*  */}
        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">ShopManager+</h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Android</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Java</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>SQLite</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Firebase</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This was the app developed and designed by a team of 3 people. Its a
            shop management app which allows managers to adjust the inventory
            based on the current requirements of the shop. Part of my
            responsibility was to develop and design a portion of the shop
            management application, along with a portion of the database, along
            with the necessary documentation and report work. The whole team was
            involved with the planning and the ideation phase.

            
          </p>
          <div className=" flex flex-row justify-center my-4">
            <a href="https://github.com/Loner291999/Shop-Manager-App" target="_blank">
              <Github />
            </a>
          </div>
        </div>
        {/*  */}

        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">
            Gucci Puzzle for the Burj Al Arab Popup
          </h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Unity 3D</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>C#</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Windows</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Ardity</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Arduino</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>C#</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>C</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This was a Unity based game activation for Gucci for the Burj al
            Arab Popup event that happened in November of 2021. It was a puzzle
            game, which was part of the whole maze game Gucci planned for the
            event. The game was on a vending machine, and a successful
            completion if it would reward the user with the clue in a form of a
            letter sent from the vending machine. I designed and developed the
            game, along with some of the mechatronic aspects.
          </p>
          <div className=" flex flex-row justify-center my-4">
            <a
              href="https://www.behance.net/gallery/132594749/Gucci-Puzzle-Game"
              target="_blank"
            >
              <Behance />
            </a>
          </div>
        </div>
        {/*  */}

        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">Community Hospital Online</h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>React</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>JS</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>CSS</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Node</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This was a website created by a group of 5 people. As the name
            suggest, this site acts as a front for an online doctor helpline,
            which the users could access by picking a doctor of their choice for
            their requirement, and requesting an online appointment in a form of
            a chat or video service, also provided by the website. My
            responsibilities were to develop, prototype and design few of the
            webpages of the website including, but not limited to, a personal
            profile of both doctor and patient, and a homepage.
          </p>
          <div className=" flex flex-row justify-center my-4">
           {/* <a href="https://github.com/Loner291999" target="_blank">
              <Github /> 
            </a>*/}
          </div>
        </div>
        {/*  */}
        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">PrayerTime app</h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Flutter</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Android</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Dart</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>JSON</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Java</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Kotlin</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Figma</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This was an app, intiially created on Flutter but now being ported
            on native Android, that displays prayer times and notifies users
            when the prayer happens. Responsible for its entire development,
            designing, prototyping and testing process
          </p>
        </div>
        {/*  */}
        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">Soundboard app</h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Flutter</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Android</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Dart</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>iOS</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Figma</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This is a Flutter app that plays audio samples for its users. I made
            it to accompany me for my Twitch streams. Future features include
            the ability for users to add their own sounds instead of the
            suggested ones.
          </p>
        </div>
        {/*  */}
        <div className="wd-200 h-200 text-center md:text-left rounded-[50px] p-7 md:p-10 bg-[#FF3C64] shadow-lg shadow-[#ff3c6396]">
          <h3 className="text-[24px] pb-2">Walkman TPS-L2</h3>
          <div className="flex flex-wrap">
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Autodesk Maya</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Arnold Renderer</span>
            </div>

            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>Davinci Resolve</span>
            </div>
            <div className="bg-[#951831] px-3 py-1 my-1 mx-0.5 rounded-full">
              <span>3D Modelling & Animation</span>
            </div>
          </div>
          <p className="font-sans leading-tight text-justify md:text-left py-3">
            This was a 3D Modelling and Animations university project I created,
            which is a 3D model of well, a 1979 Walkman. I created a mini ad of
            sorts to accompay it since it was a part of the submission, which
            appeals the users to its design and nostalgia factor
          </p>
          <div className=" flex flex-row justify-center my-4">
            <a href="https://www.artstation.com/artwork/nQVYnK" target="_blank">
              <Art />
            </a>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
}
