import React from "react";

export default function behance() {
  return (
    <div className="rounded-full shadow-md mx-2 w-12 bg-[#222A36] h-12 px-3 py-2.5 shadow-[#ff3c64]">
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-center"
      >
        <path
          d="M8.9519 12.6489C8.9519 12.6489 11.0507 12.4931 11.0507 10.0312C11.0507 7.5696 9.33347 6.36865 7.158 6.36865H3.15391H3.03629H0V20.1248H3.03629H3.15391H7.158C7.158 20.1248 11.5277 20.263 11.5277 16.0647C11.5279 16.0647 11.7185 12.6489 8.9519 12.6489ZM3.15391 8.81385H6.64285H7.15824C7.15824 8.81385 8.13136 8.81385 8.13136 10.2449C8.13136 11.676 7.55878 11.8835 6.91003 11.8835H3.15391V8.81385ZM6.97347 17.6803H3.15391V14.0038H7.158C7.158 14.0038 8.60831 13.9853 8.60831 15.8931C8.60831 17.484 7.54767 17.6643 6.97347 17.6803Z"
          fill="#FF3C64"
        />
        <path
          d="M20.0722 7.17896H14.3965V8.87307H20.0722V7.17896Z"
          fill="#FF3C64"
        />
        <path
          d="M17.356 9.8689C12.066 9.8689 12.0706 15.1538 12.0706 15.1538C12.0706 15.1538 11.708 20.4126 17.3562 20.4126C17.3562 20.4126 22.0635 20.6816 22.0635 16.7542H19.6426C19.6426 16.7542 19.7234 18.2339 17.437 18.2339C17.437 18.2339 15.0164 18.3957 15.0164 15.8398H22.144C22.144 15.8398 22.9238 9.8689 17.356 9.8689ZM19.5078 14.0038H14.9891C14.9891 14.0038 15.2847 11.8834 17.4097 11.8834C19.5349 11.8832 19.5078 14.0038 19.5078 14.0038Z"
          fill="#FF3C64"
        />
      </svg>
    </div>
  );
}
