import React from "react";

export default function yt() {
  return (
    <div className="rounded-full shadow-md mx-2 w-12 bg-[#222A36] h-12 px-3 py-2.5 shadow-[#ff3c64]">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0155 3.6001H4.98449C2.23163 3.6001 0 5.83173 0 8.58459V15.5986C0 18.3515 2.23163 20.5831 4.98449 20.5831H19.0155C21.7684 20.5831 24 18.3515 24 15.5986V8.58459C24 5.83173 21.7684 3.6001 19.0155 3.6001ZM15.6445 12.4329L9.08177 15.5629C8.9069 15.6463 8.7049 15.5188 8.7049 15.3251V8.86936C8.7049 8.67288 8.91221 8.54554 9.08744 8.63436L15.6502 11.96C15.8453 12.0589 15.8419 12.3387 15.6445 12.4329Z"
          fill="#FF3C64"
        />
        <path
          d="M19.0155 3.6001H4.98449C2.23163 3.6001 0 5.83173 0 8.58459V15.5986C0 18.3515 2.23163 20.5831 4.98449 20.5831H19.0155C21.7684 20.5831 24 18.3515 24 15.5986V8.58459C24 5.83173 21.7684 3.6001 19.0155 3.6001ZM15.6445 12.4329L9.08177 15.5629C8.9069 15.6463 8.7049 15.5188 8.7049 15.3251V8.86936C8.7049 8.67288 8.91221 8.54554 9.08744 8.63436L15.6502 11.96C15.8453 12.0589 15.8419 12.3387 15.6445 12.4329Z"
          fill="#FF3C64"
        />
      </svg>
    </div>
  );
}
