import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ppdf from "../content/muhammad-hassan-naseer-cv.pdf";
import meimage from "../content/le-me-grad.jpg";
import Linkedin from "../svgs/linkedin";
import Art from "../svgs/art";
import Behance from "../svgs/behance";
import Github from "../svgs/github";

export default function About() {
  return (
    <div className="pt-20 px-10 md:px-20 bg-[#222A36] min-h-screen" id="about">
      <p className="text-white text-center md:text-left text-[48px] md:text-[48px] lg:text-[96px]">
        About Me
      </p>
      <div className="  flex flex-col lg:flex-row  ">
        <div className="pt-5  text-white leading-relaxed font-sans text-justify md:text-justify lg:text-left text-[16px] md:text-[20px] lg:text-[20px] min-w-[50%]">
          <span>
            I am a recent graduate from University of Wollongong in Dubai (UOWD)
            in Computer Science. Currently based in Dubai, my main focus areas
            and interest are mobile app development, game development, and UI/UX
            designing. <br />
            <br />
            I am fine with working on any form of software development should
            the opportunity be presented. I have previously worked in Artificial
            Intelligence, Mechatronics, and XR development, and am not afraid to
            get my hands dirty in other fields, even if its just an internship.
            I have had my fair share of video editing and 3D art-work.
            <br />
            <br /> In my past time, I am either at the gym, or playing video
            games and drinking orange juice or submit myself into the web of
            geek culture, YouTube, and k-dramas.
          </span>
          <div className=" flex flex-row justify-center my-16">
            <a href="https://www.artstation.com/muhammadnaseer" target="_blank">
              <Art />
            </a>
            <a href="https://www.behance.net/muhammadnaseer" target="_blank">
              <Behance />
            </a>

            <a href="https://github.com/Loner291999" target="_blank">
              <Github />
            </a>

            <a href="https://www.linkedin.com/in/hassan291999/" target="_blank">
              <Linkedin />
            </a>
          </div>
        </div>

        <div className="min-w-[40%] pt-5 lg:pt-0 flex justify-center ">
          <div className="w-4/5 mx-auto self-center ">
            <img
              src={meimage}
              className="rounded-lg border-2 border-[#FF3C64] shadow-lg shadow-[#ff3c6396]"
              alt="deeznuts"
            ></img>
            {/* <LazyLoadImage className="rounded-lg border-2 border-[#FF3C64] shadow-lg shadow-[#ff3c6396]"
              src={
                meimage
              }
              effect=""
              alt="deeznuts"
            /> */}
          </div>
        </div>
      </div>
      <div className=" flex flex-row mt-20 justify-center">
        <div>
          <button className="rounded-md shadow-md  shadow-[#FF3C64] p-5 text-white bg-[#FF3C64]">
            {" "}
            <a href={ppdf} target="_blank">
              {" "}
              Check my Resume
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
