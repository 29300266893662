import React from "react";
import Typewriter from 'typewriter-effect';
import homepageimg from "../content/homepage-image-for-portfolio.png"

export default function Home() {
  return (
    <div
      className=" px-20 bg-[#222A36] flex flex-col md:flex-row min-h-screen "
      id="home"
    >
        
      <div className="  text-white pt-20 text-center md:text-left  text-[48px] md:text-[72px] lg:text-[72px] min-w-[45%]">
        <h5>
          Hi, this is{" "}
          <span className="font-serif italic underline decoration-[#FF3C64]   hover:text-[#FF3C64]">
            Hassan 
          </span> {" "}
           and I am a <span className="font-mono">
          <Typewriter 
            options={{
              strings: ["software developer", "game developer", "mobile app developer",  "streamer", "pop geek culture enthusiast", "tech enthusiast", "masafi stan"],
              autoStart: true,
              loop: true,
            }}
          /> </span>
        </h5>
      </div>
      <div className="min-w-[50%] my-auto "> 
      <img src={homepageimg} alt="deeznuts"  className="mx-auto"></img>
      </div>
    </div>
  );
}
