import React from "react";

export default function art() {
  return (
    <div className="rounded-full shadow-md px-3.5 bg-[#222A36] py-3.5 mx-2 w-12 h-12 shadow-[#ff3c64]">
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 13.5944L1.69239 16.5188C2.02696 17.18 2.70778 17.6326 3.49892 17.6326H14.7318L12.4018 13.5944H0V13.5944ZM19.6713 12.5161L13.13 1.07448C12.7915 0.436914 12.1184 0 11.3431 0H7.86379L17.9907 17.6168L19.5926 14.8421C19.6674 14.7162 20.4191 13.6731 19.6713 12.5161ZM10.7448 10.7251L6.19896 2.85352L1.65304 10.7251H10.7448Z"
          fill="#FF3C64"
        />
      </svg>
    </div>
  );
}
