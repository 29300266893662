
import './App.css';
import About from './components/About';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Portfolio from './components/Portfolio';


function App() {
  return (
    <div className='font-Fredoka bg-[#222A36]'>
      <Navbar/>
      
      <Home/>
      <About/>
      <Portfolio/>
      
      <div className='text-center h-10 text-white text-[12px] font-sans py-20'> Built and designed by Muhammad Hassan Naseer <br/> All Rights Reserved </div>
    </div>
  );
}

export default App;
