import React from "react";

export default function github() {
  return (
    <div className="rounded-full shadow-md mx-2 bg-[#222A36]  w-12 h-12 px-2.5 py-3 content-center shadow-[#ff3c64]">
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.5415 0C7.25682 0 2.1665 5.13939 2.1665 11.4847C2.1665 16.5667 5.4226 20.8591 9.94416 22.3808C10.5129 22.4813 10.7262 22.1367 10.7262 21.8353C10.7262 21.5625 10.712 20.6581 10.712 19.6962C7.854 20.2274 7.11463 18.9928 6.88713 18.3468C6.75916 18.0166 6.20463 16.9973 5.72119 16.7246C5.32307 16.5092 4.75432 15.9781 5.70697 15.9637C6.60275 15.9494 7.2426 16.7963 7.45588 17.1409C8.47963 18.8779 10.1148 18.3898 10.7688 18.0884C10.8684 17.3419 11.167 16.8394 11.494 16.5523C8.96307 16.2652 6.31838 15.2746 6.31838 10.8817C6.31838 9.63278 6.75916 8.59916 7.48432 7.79523C7.37057 7.50811 6.97244 6.33093 7.59807 4.75179C7.59807 4.75179 8.55072 4.45031 10.7262 5.92897C11.6362 5.67056 12.6031 5.54136 13.5699 5.54136C14.5368 5.54136 15.5037 5.67056 16.4137 5.92897C18.5892 4.43596 19.5418 4.75179 19.5418 4.75179C20.1674 6.33093 19.7693 7.50811 19.6556 7.79523C20.3807 8.59916 20.8215 9.61842 20.8215 10.8817C20.8215 15.289 18.1626 16.2652 15.6317 16.5523C16.044 16.9112 16.3995 17.6003 16.3995 18.677C16.3995 20.213 16.3853 21.4476 16.3853 21.8353C16.3853 22.1367 16.5985 22.4956 17.1673 22.3808C21.6604 20.8591 24.9165 16.5523 24.9165 11.4847C24.9165 5.13939 19.8262 0 13.5415 0Z"
          fill="#FF3C64"
        />
      </svg>
    </div>
  );
}
