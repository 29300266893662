import React from 'react'
import { Link } from "react-scroll";

export default function Navbar() {
    return (
        <div className=' sticky top-0 left-0 right-0 flex flex-row items-center justify-between px-10 md:px-20  min-w-min h-20  bg-[#222A36] text-[#FF3C64] shadow-lg shadow-[#FF3C64]'>
            <Link to="home" smooth={true}>
                <button>Hassan</button>
                </Link>
            
            <div className="flex flex-row space-x-8 ">
                <Link to="about" smooth={true}>
                <button>About</button>
                </Link>
                <Link to="portfolio" smooth={true}>
                <button>Portfolio</button>
                </Link>
                
            </div>
        </div>
    )
}
